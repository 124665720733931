<template>
    <div class="single-testimonial">
        <p>
            TESTIMONIAL
        </p>
        <br>
        <p>
            {{data.content}}
        </p>
        <p>
            {{data.author}}
        </p>
    </div>
</template>

<script>
export default {
    name: "SingleTestimonial",
    props: ['data']
}
</script>

<style lang="scss" scoped>
.single-testimonial{
    padding: 53px 20%;
    text-align: center;
    background: #1a1a1a;
}
@media (max-width: $mobile) { 
    .single-testimonial{
        padding: 2rem;
        text-align: center;
        background: #1a1a1a;
    }
}
</style>
