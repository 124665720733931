<template>
    <div class="before-after-container">
        <h2>
            {{data.title}}
        </h2>
        <hr>
        <div class="before-after-comparison-container desktop">
            <div class="before-after-slideshow">
                <vueper-slides
                    class="no-shadow"
                    :bullets="false"
                    :visible-slides="2"
                    slide-multiple
                    :slide-ratio="1 / 4"
                    :dragging-distance="70"
                    :gap="5"
                    :arrows-outside="false">
                    <vueper-slide
                        v-for="(slide, i) in data.images"
                        :key="i"
                        :image="slide"
                        class="image_slide"
                    >
                    </vueper-slide>
                </vueper-slides>
            </div>
            <div class="before-after-comparison">
                <div class="before">
                    <h3>
                        Before
                    </h3>
                    <p v-for="(info, i) in data.before" :key="i">
                        {{info}}
                    </p>
                </div>
                <div class="after">
                    <h3>
                        After
                    </h3>
                    <p v-for="(info, i) in data.after" :key="i">
                        {{info}}
                    </p>
                    <router-link :to="data.link">
                        Details
                    </router-link>
                </div>
            </div>
        </div>
        <div class="before-after-container mobile">
            <div class="mobile-slideshow">
                <vueper-slides
                    class="no-shadow"
                    :bullets="false"
                    slide-multiple
                    :slide-ratio="1 / 4"
                    :dragging-distance="70"
                    :gap="5"
                    :fixed-height="'300px'"
                    :arrows-outside="false">
                    <vueper-slide
                        v-for="(slide, i) in beforeData"
                        :key="i"
                        :image="slide"
                        class="image_slide"
                    >
                    </vueper-slide>
                </vueper-slides>
            </div>
            <div class="before-after-comparison">
                <div class="before">
                    <h3>
                        Before
                    </h3>
                    <p v-for="(info, i) in data.before" :key="i">
                        {{info}}
                    </p>
                </div>
            </div>
        </div>
        <div class="before-after-container mobile">
            <div class="mobile-slideshow">
                <vueper-slides
                    class="no-shadow"
                    :bullets="false"
                    slide-multiple
                    :slide-ratio="1 / 4"
                    :dragging-distance="70"
                    :gap="5"
                    :fixed-height="'300px'"
                    :arrows-outside="false">
                    <vueper-slide
                        v-for="(slide, i) in afterData"
                        :key="i"
                        :image="slide"
                        class="image_slide"
                    >
                    </vueper-slide>
                </vueper-slides>
            </div>
            <div class="before-after-comparison">
                <div class="after">
                    <h3>
                        After
                    </h3>
                    <p v-for="(info, i) in data.after" :key="i">
                        {{info}}
                    </p>
                    <router-link :to="data.link">
                        Details
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
export default {
    name: "BeforeAfter",
    components: { VueperSlides, VueperSlide},
    props: ['data'],
    computed: {
        beforeData: function(){
            let ar = [];
            for (var i = 0; i < this.data.images.length; i++) {
                if(i % 2 === 0) {
                    ar.push(this.data.images[i]);
                }
            }
            return ar;
        },
        afterData: function(){
            let ar = [];
            for (var i = 0; i < this.data.images.length; i++) {
                if(i % 2 === 1) {
                    ar.push(this.data.images[i]);
                }
            }
            return ar;
        }
    },
}
</script>

<style lang="scss" scoped>
.before-after-container{
    margin: 53px 4rem;
    color: #fff;
    h2{
        font-weight: 600;
        text-align: center;
        font-size: 13px;
        letter-spacing: 3px;
        margin: 23px auto;
        margin-top: 46px;
        text-transform: uppercase;
    }
    hr {
        text-align: center;
        width: 60%;
        height: 1px;
        border: 0; 
        border-bottom: 1px solid #fff;
        opacity: 0.15;
        margin:0 auto;
        margin-bottom: 53px;
    }
    .before-after-comparison{
        display: flex;
        .before, .after{
            width: 50%;
            h3{
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: 50px;
                line-height: 50px;
                font-weight: 100;
                margin:53px 0;
            }
        }
        .before{
            text-align: right;
            margin-right: 2rem;
        }
        .after{
            margin-left: 2rem;
            p{
                opacity: 1;
                color: #fff;
            }
            a{
                display: block;
                text-transform: uppercase;
                opacity: 0.5;
                font-size: 20px;
            }
        }
    }
}
.mobile{
    display: none;
}
@media (max-width: $mobile) { 
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
    }
    .before-after-container{
        margin: 2rem 0;
        .before-after-comparison{
            .before, .after{
                width: 100%;
                text-align: left;
                margin-left: 2rem;
            }
        }
    }
}
</style>
